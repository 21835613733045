<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="justify-content-end mr-1">
          <b-button variant="primary" class="ml-1" @click="reloadPage">
            <feather-icon
              class="cursor-pointer"
              :class="isBusy ? 'lds-ring' : ''"
              icon="RefreshCcwIcon"
              size="14"
            />
            Reload
          </b-button>
        </b-row>
      </template>
    </header-slot>
    <div>
      <b-row class="mb-3 ml-0">
        <div class="text-info">
          * These prices will be used at the time of making a charge.
        </div>
      </b-row>
      <validation-observer ref="form">
        <b-row class="pr-1">
          <template v-for="(commissionData, index) in commissionsData">
            <b-col
              v-if="
                commissionData.category !== 1 &&
                commissionData.category !== 5 &&
                commissionData.category !== 4
              "
              :key="index"
              md="4"
            >
              <b-form-group
                v-if="!isBusy"
                label-class="font-bureau-style font-weight-normal color-gray-input-sm"
                label-cols-lg="5"
                label-cols-md="4"
                label-cols-sm="3"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="9"
              >
                <template #label>
                  <feather-icon
                    icon="SquareIcon"
                    style="margin-right: 0.5rem"
                    :class="
                      commissionData.category === 1 ||
                      commissionData.category === 4
                        ? 'text-primary'
                        : 'text-warning'
                    "
                    :style="
                      commissionData.category === 1 ||
                      commissionData.category === 4
                        ? 'fill: #0090e7'
                        : 'fill: #ff9f43'
                    "
                  />
                  <span> {{ commissionData.charge_description.toUpperCase() }} </span>
                </template>

                <div class="w-100" :class="'d-flex justify-content-between'">
                  <validation-provider
                    v-if="commissionData.category == 2"
                    v-slot="{ errors }"
                    :name="commissionData.description + (index + 1)"
                    rules="required|validate-amount-zero"
                    class="w-100"
                  >
                    <b-input-group>
                      <money
                        v-model="commissionData.amount"
                        v-bind="vMoneyDollars"
                        class="form-control input-form ml-1"
                        :disabled="!commissionData.editingAmount"
                      />
                      <b-input-group-append
                        v-if="commissionData.editingAmount"
                        class="border-right"
                      >
                        <b-button
                          variant="outline-primary"
                          class="btn-icon"
                          @click="saveMinAmountChange(commissionData)"
                        >
                          <feather-icon
                            icon="SaveIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-button
                          v-if="!commissionData.editingAmount"
                          variant="outline-warning"
                          class="btn-icon"
                          @click="enableEditMinAmount(index)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="cursor-pointer"
                          />
                        </b-button>
                        <b-button
                          v-if="commissionData.editingAmount"
                          variant="outline-danger"
                          class="btn-icon"
                          @click="cancelEditMinAmount(index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <span v-if="errors[0]" class="text-danger">{{
                      errors[0]
                    }}</span>
                  </validation-provider>
                </div>
              </b-form-group>
              <div v-if="isBusy" class="d-flex mb-1">
                <b-skeleton width="20%" />
                <b-skeleton type="input" width="100%" />
              </div>
            </b-col>
          </template>
        </b-row>
      </validation-observer>
      <list-tracking-ced-commissions
        v-if="openListTrackingCedCommissionsOn"
        :id-commission="idToSend"
        :type-commission="typeCommission"
        :type-table="typeTable"
        @canceledChange="reloadPage"
        @close="closeListTrackingCedCommission"
      />
    </div>
  </div>
</template>

<script>
import CeSetCommissionsService from "@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service"
import ListTrackingCedCommissions from "@/views/ce-digital/sub-modules/settings/views/commissions/components/modals/ListTrackingCedCommissions.vue"
import { mapGetters } from "vuex"

export default {
  components: {
    ListTrackingCedCommissions,
  },
  data() {
    return {
      idToSend: 0,
      typeCommission: 0,
      vMoney: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        min: 0.0,
        maxlength: 10,
      },
      vMoneyDollars: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        prefix: "$ ",
        min: 0.0,
        maxlength: 10,
      },
      openListTrackingCedCommissionsOn: false,
      commissionsData: [
        // typeCommission: 1 amount 2 percentage, category: 1 action 2 charge
      ],
      rolesCommissionData: [],
      isBusy: false,
      isMoving: true,
      typeTable: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleIdNum() {
      return this.$route.meta.moduleIdNum
    },
  },
  async created() {
    await this.getCommissionData()
  },
  methods: {
    closeListTrackingCedCommission() {
      this.openListTrackingCedCommissionsOn = false
    },
    openListTrackingCedCommissions(item, typeCommission, type) {
      this.idToSend = type === 1 ? item.id : item.real_id
      this.typeCommission = typeCommission
      this.typeTable = type
      this.openListTrackingCedCommissionsOn = true
    },
    editCommission(commissionData, index, type) {
      if (type === 1) {
        this.commissionsData[index].savedData =
          this.commissionsData[index].value
        this.commissionsData[index].editingValue = true
      } else {
        this.rolesCommissionData[index].savedData =
          this.rolesCommissionData[index].value
        this.rolesCommissionData[index].editingValue = true
      }
    },
    cancelEditCommission(commissionData, index, type) {
      if (type === 1) {
        this.commissionsData[index].value =
          this.commissionsData[index].savedData
        this.commissionsData[index].editingValue = false
      } else {
        this.rolesCommissionData[index].value =
          this.rolesCommissionData[index].savedData
        this.rolesCommissionData[index].editingValue = false
      }
    },
    async saveCommission(commissionData, index, type) {
      const commissionArray =
        type === 1 ? this.commissionsData : this.rolesCommissionData
      try {
        if (commissionArray[index].value !== commissionArray[index].savedData) {
          const result = await this.$refs.form.validate()
          if (result) {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              const data = await CeSetCommissionsService.saveChangeCommission({
                new_value: commissionData.value,
                old_value: commissionData.savedData,
                type_data_commission: commissionData.typeCommission,
                type_commission: commissionData.id,
                type_commission_description: commissionData.description,
                created_by: this.currentUser.user_id,
                status: 1, // Pending
                user_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                type,
              })
              if (data.status === 200) {
                await this.getCommissionData()
                await this.getCedCommissionRoles()
                this.showGenericSuccessSwal({})
              }
            }
          }
        } else {
          this.showWarningSwal(
            "Invalid Change",
            "The entered value is the same as the old one"
          )
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    enableEditMinAmount(index) {
      this.commissionsData[index].savedData = this.commissionsData[index].amount
      this.commissionsData[index].editingAmount = true
    },
    cancelEditMinAmount(index) {
      this.commissionsData[index].amount = this.commissionsData[index].savedData
      this.commissionsData[index].editingAmount = false
    },
    async saveMinAmountChange(commissionData) {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const data =
              await CeSetCommissionsService.updateMinAmountCommission({
                type_commission: commissionData.id,
                amount: commissionData.amount,
              })
            if (data.status === 200) {
              await this.getCommissionData()
              await this.getCedCommissionRoles()
              this.commissionsData[index].editingAmount = false
              this.showGenericSuccessSwal({})
            }
          }
        }
      } catch (e) {
        console.log("ERRORS ON CommissionsPerModule : saveMinAmountChange")
      }
    },
    async reloadPage() {
      await this.getCommissionData()
      await this.getCedCommissionRoles()
    },
    async getCommissionData() {
      try {
        let fake = []
        this.commissionsData = []
        this.isBusy = true
        const data = await CeSetCommissionsService.getCommissionData({
          module_id: 22,
        })
        if (data.status === 200) {
          fake = data.data
          fake.forEach((commission) => {
            commission.editingAmount = false
            commission.editingValue = false
            commission.savedData = 0
            commission.value = parseFloat(commission.value)
          })
          this.commissionsData = fake
          setTimeout(() => {
            this.isBusy = false
          }, 900)
        }
      } catch (e) {
        this.removePreloader()
        this.showErrorSwal(e)
        this.isBusy = false
      }
    },
  },
}
</script>

<style scoped>
.lds-ring {
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
